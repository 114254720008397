import { axiosInstance as axios } from '../lib/axios';

export const getImageModals = async () => {
  try {
    const resposta = await axios.get('/content/modals?populate=*');
    return { data: resposta.data.data, error: null };
  } catch (err) {
    console.log(err)
  }
};

export const getAlerts = async () => {
  try {
    const response = await axios.get('/content/messages?populate=*');
    const data = response.data.data.map(item => ({
      id: item.id,
      documentId: item.documentId,
      content: item.content,
      color: item.color,
      startDate: item.startDate,
      endDate: item.endDate
    }));
    return { data, error: null };
  } catch (err) {
    console.log('Erro ao carregar as mensagens:', err);
    return { data: [], error: err.message || 'Erro ao carregar as mensagens.' };
  }
};

export const getAlertPIXSales = async () => {
  try {
    const url = "/content/aba-venda-pixs?fields[0]=message"
    const { data } = await axios.get(url);
    const { message } = data.data.find(item => item.message);
    return { message };
  } catch (err) {
    console.log(err)
  }
};
export const getAlertPIXTip = async () => {
  try {
    const url = "/content/aba-gorjeta-vips?fields[0]=message"
    const { data } = await axios.get(url);
    const { message } = data.data.find(item => item.message);
    return { message };
  } catch (err) {
    console.log(err)
  }
};
export const getAlertPIXTerms = async () => {
  try {
    const url = "/content/aba-termos-e-condicoes?fields[0]=message"
    const { data } = await axios.get(url);
    const { message } = data.data.find(item => item.message);
    return { message };
  } catch (err) {
    console.log(err)
  }
};

